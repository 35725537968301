<template>
  <v-carousel
    :hide-delimiters="produto.imagens.big.length < 2"
    :next-icon="produto.imagens.big.length < 2 ? false : undefined"
    :prev-icon="produto.imagens.big.length < 2 ? false : undefined"
    height="auto"
    :cycle="cycle"
    interval="3000"
  >
    <v-carousel-item
      v-for="imagem in produto.imagens.big"
      :key="imagem"
      eager
      @mouseover="setCycle(false)"
      @mouseout="setCycle(true)"
    >
      <v-img :alt="produto.nome" contain :src="imagem" :max-height="height" eager />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "CarouselProduto",

  props: {
    produto: {
      type: Object,
      required: true
    },

    height: String
  },

  data: () => ({
    cycle: true
  }),

  methods: {
    setCycle(value) {
      this.cycle = value;
    }
  }
};
</script>
