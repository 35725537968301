<template>
  <v-container v-if="produto">
    <v-row>
      <v-col v-if="isDesktop" cols="12" md="6">
        <v-card elevation="1">
          <carousel-produto :produto="produto" height="500" />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          :elevation="isDesktop ? 1 : 0"
          :color="'rgb(255, 255, 255, ' + (isDesktop ? '1' : '0') + ')'"
          :height="isDesktop ? 500 : undefined"
          :class="{ 'pa-5': isDesktop }"
        >
          <h1 class="text-h6">{{ produto.nome }}</h1>
          <carousel-produto v-if="!isDesktop" class="mt-1" :produto="produto" height="368" />
          <div class="text-h6 mt-5 green--text">
            R$ {{ produto.preco.toFixed(2).replace(".", ",") }}
          </div>
          <div class="my-5">{{ produto.descricoes.curta }}</div>
          <div v-if="produto.mostrarDetalhamento" class="mb-6">
            <a href="javascript: void(0)" @click="irParaDetalhes">Ver descrição completa</a>
          </div>
          <v-btn
            v-if="produto.estoque >= 1"
            color="primary"
            block
            :href="produto.afiliacao ? produto.linkCompra : linkWhatsApp(mensagem)"
            target="_blank"
            large
          >
            Comprar {{ produto.afiliacao ? "agora" : "via Whatsapp" }}
          </v-btn>
          <div v-else class="text-h6 red--text">Este produto está indisponível no momento :(</div>
        </v-card>
      </v-col>
    </v-row>
    <v-card
      v-if="produto.mostrarDetalhamento"
      :class="{ 'pa-7': isDesktop }"
      :elevation="isDesktop ? 1 : 0"
      :color="'rgb(255, 255, 255, ' + (isDesktop ? '1' : '0') + ')'"
      class="mt-5"
      id="detalhamentoProduto"
    >
      <v-row>
        <v-col cols="12" md="9">
          <v-tabs v-model="tabSelecionada">
            <v-tab v-for="(tab, i) in tabs" :key="i">
              {{ tab.titulo }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabSelecionada" class="mt-5">
            <v-tab-item v-for="(tab, i) in tabs" :key="i">
              <v-simple-table v-if="Array.isArray(tab.conteudo)">
                <tbody>
                  <tr v-for="item in tab.conteudo" :key="item.id">
                    <th>{{ item.label }}</th>
                    <td>{{ item.valor }}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <div v-else v-html="tab.conteudo"></div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="12" md="3">
          <h3 class="mb-5">Produtos relacionados</h3>
          <card-produto
            v-for="produtoRelacionado in produto.produtosRelacionados"
            :produto="produtoRelacionado"
            :key="produtoRelacionado.id"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import CardProduto from "../components/CardProduto.vue";
import CarouselProduto from "../components/CarouselProduto.vue";
import ProdutoRepository from "@/repositories/ProdutoRepository";

export default {
  components: { CarouselProduto, CardProduto },

  name: "Produto",

  props: {
    idProduto: String
  },

  data: () => ({
    produto: null,
    mensagem: "Olá, gostaria de comprar o produto ",
    tabSelecionada: null,
    tabs: []
  }),

  async created() {
    this.produto = await ProdutoRepository.buscarPorRota(this.idProduto);
    this.mensagem += this.produto.nome;
    this.tabs = [
      { titulo: "Descrição", conteudo: this.produto.descricoes.longa },
      { titulo: "Modo de uso", conteudo: this.produto.descricoes.opcional1 }
    ];

    for (const caracteristicas of this.produto.caracteristicas) {
      this.tabs.push({
        titulo: caracteristicas.nome,
        conteudo: caracteristicas.itens
      });
    }
  },

  methods: {
    irParaDetalhes() {
      const rect = document.getElementById("detalhamentoProduto").getBoundingClientRect();
      console.log(window.scrollY, rect.top);
      window.scrollTo(0, window.scrollY + rect.top - (this.isDesktop ? 112 : 56));
    }
  },

  metaInfo() {
    return {
      title: `Erótica BH - ${this.produto?.nome}`,
      meta: [
        {
          name: "description",
          content: this.produto?.descricao?.curta
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.box-img {
  @media only screen and (min-width: 960px) {
    background-color: white;
  }
}

.v-tab {
  @media only screen and (max-width: 960px) {
    width: 112px;
  }
}
</style>

<style lang="scss">
.v-slide-group__prev--disabled {
  display: none !important;
}
</style>
